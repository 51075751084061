import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getSunrise, getSunset } from "sunrise-sunset-js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background: black;
  justify-content: center;
  width: 100%;
  height: 100%;
  micrio {
    width: 100%;
    height: 100%;
  }
`;

const dayId = "RDllz";
const nightId = "ujpSG";

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

const MicrioPage = () => {
  const size = useWindowSize();
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [isSunset, setIsSunset] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(currentTime.add(1, "second"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("Europe/London");

    const LAT = 50.1245;
    const LONG = -5.6792;
    const sunrise = dayjs(getSunrise(LAT, LONG));
    const sunset = dayjs(getSunset(LAT, LONG));
    const now = dayjs();

    const getMinutes = (time) => time.hour() * 60 + time.minute();

    const sunriseMinutes = getMinutes(sunrise);
    const sunsetMinutes = getMinutes(sunset);
    const nowMinutes = getMinutes(now);

    if (nowMinutes > sunriseMinutes && nowMinutes < sunsetMinutes) {
      setIsSunset(false);
    } else {
      setIsSunset(true);
    }
  }, [currentTime]);

  return (
    <>
      <Container sunset={isSunset}>
        <iframe
          src={`https://micr.io/i/${isSunset ? nightId : dayId}/`}
          width={size.width}
          height={size.height}
          allow="autoplay; fullscreen"
        ></iframe>
      </Container>
    </>
  );
};

export default MicrioPage;
